import { createSelector } from "reselect";
import { getActiveOrganization } from "./organizations";
import { getUserData } from "./user";
import Constants from "const/Constants";
import Utils from "utils/Utils";

const { DEV_ORG_IDS } = Constants;

export const checkPreloaderShown = ({ ui: { preloaderShown } }) => preloaderShown;

export const checkPreloaderOverlayShown = ({ ui: { preloaderOverlayShown } }) => preloaderOverlayShown;

export const checkModalContentShown = ({ ui: { modalContentShown } }) => modalContentShown;

export const getModalWindowConfig = ({ ui: { modalWindowConfig } }) => modalWindowConfig;

export const getPreloaderText = ({ ui: { preloaderText } }) => preloaderText;

export const getModalImages = ({ ui: { modalImages } }) => modalImages;

export const getOpenedModalsData = ({ ui: { openedModals } }) => openedModals;

export const checkExperimentalFeatureAllowed = createSelector(
  [getUserData, getActiveOrganization],
  ({ superAdmin }, { id: activeOrganizationId }) => {
    return !Utils.checkIsProductionEnv() || superAdmin || DEV_ORG_IDS.includes(activeOrganizationId);
  }
);
