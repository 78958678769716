import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";

const SIZES = {
  sm: Css.sizeSmall,
  small: Css.sizeSmall
};

const BORDER = {
  primary: Css.borderPrimary,
  success: Css.borderSuccess,
  warning: Css.borderWarning,
  danger: Css.borderDanger
};

const Input = (props) => {
  const innerRef = useRef();

  const {
    className,
    selectOnFocus,
    type = "text",
    multiline,
    resize = true,
    size,
    border,
    value,
    valid,
    invalid,
    warning,
    focused,
    active,
    highlight,
    onChange,
    iconBefore,
    cleanable,
    inputRef = innerRef,
    disabled,
    error,
    autoHeight,
    readOnly,
    autoFocus,
    iconBeforeTitle,
    onFocus,
    ...restProps
  } = props;

  const Component = multiline ? "textarea" : "input";

  const IconBefore = useMemo(() => [
    iconBefore,
    !disabled && invalid && Icons.WarningCircle,
    !disabled && valid && Icons.CheckCircle
  ].find(Boolean), [disabled, iconBefore, invalid, valid]);

  const handleChange = useCallback((event) => {
    onChange(event.target.value, event);
  }, [onChange]);

  const handleCleanClick = useCallback((event) => {
    if (onChange) onChange("", event, true);
  }, [onChange]);

  useEffect(() => {
    if (autoHeight && multiline) {
      const target = inputRef.current;

      target.style.overflow = "hidden";

      setTimeout(() => {
        target.style.height = "auto";

        const height = `${target.scrollHeight}px`;

        target.style.height = height;
        if (
          target.scrollHeight > parseInt(getComputedStyle(target).maxHeight, 10)
        ) {
          target.style.overflow = "";
        }
      }, 0);
    }
  }, [value, autoHeight, multiline, inputRef]);

  const handleInputFocus = useCallback((event) => {
    if (selectOnFocus) inputRef.current?.select();
    if (onFocus) onFocus(event);
  }, [onFocus, selectOnFocus, inputRef]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  return (
    <label
      className={classNames(Css.input, {
        [Css.noResize]: !resize,
        [Css.withIconBefore]: IconBefore
      }, !disabled && !readOnly && {
        [Css.error]: error,
        [Css.valid]: valid,
        [Css.invalid]: invalid,
        [Css.warning]: warning,
        [Css.active]: active,
        [Css.highlight]: highlight
      }, SIZES[size], BORDER[border], className)}>
      <Component
        {...restProps}
        disabled={disabled}
        readOnly={readOnly}
        ref={inputRef}
        autoComplete="off"
        className={classNames({
          [Css.focused]: !disabled && !readOnly && focused,
          [Css.active]: !disabled && !readOnly && active
        })}
        value={value}
        onFocus={handleInputFocus}
        onChange={handleChange}
        type={multiline ? undefined : type} />
      {IconBefore && (
        <div className={Css.iconBefore} data-tooltip={iconBeforeTitle}>
          <IconBefore />
        </div>
      )}
      {cleanable && !disabled && !!value && (
        <div
          className={Css.iconAfter}
          onClick={handleCleanClick}>
          <Icons.X />
        </div>
      )}
    </label>
  );
};

export default React.memo(Input);
