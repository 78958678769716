import Css from "./style.module.scss";

import DropDown from "nlib/ui/DropDown";
import DropDownCaret from "nlib/ui/DropDown/lib/DropDownCaret";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import DropDownMenuItem from "nlib/ui/DropDown/lib/DropDownMenuItem";
import DropDownToggle from "nlib/ui/DropDown/lib/DropDownToggle";
import Input from "nlib/ui/Input";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";

const SIZES = {
  sm: Css.smallSize
};

const AutoCompleteInput = (props) => {
  const [dropdownOpened, setDropdownOpened] = useState();

  const [innerShowAll, setShowAll] = useState(false);

  const innerRef = useRef();

  const {
    name,
    size,
    value,
    valid,
    portal,
    prepend,
    disabled,
    readOnly,
    placeholder,
    tooltip,
    groups = [null],
    items = [],
    showAll = innerShowAll,
    filteredItems: filteredItemsFromProps,
    invalid,
    opened = dropdownOpened,
    alignRight,
    alignListRight = alignRight,
    useCaret = true,
    className,
    dropDownClassName,
    inputRef = innerRef,
    inputClassName,
    renderLabel,
    dropDownLabel,
    children,
    selectOnFocus = true,
    triggerLength,
    getItems,
    onChange,
    onAutoComplete,
    onClick,
    setOpened = setDropdownOpened,
    useTooltip,
    scrollToSelected = true,
    onFocus,
    onBlur,
    onSetShowAll,
    ...restProps
  } = props;

  const scrollRef = useRef();

  const filteredItems = useMemo(() => {
    if (!opened) return [];
    if (filteredItemsFromProps) return filteredItemsFromProps;

    const itemsToFilter = getItems ? getItems() : items;

    if (!value || showAll) return itemsToFilter;

    const localValue = value.trim().toLowerCase();

    return itemsToFilter.filter((item) => {
      if (item === value) return false;

      const label = typeof item === "string" ? item : item.label;

      if (!label) return false;

      const itemValue = label.trim().toLowerCase();

      return itemValue === localValue || itemValue.includes(localValue);
    });
  }, [opened, filteredItemsFromProps, getItems, items, value, showAll]);

  const handleInputChange = useCallback((val, event) => {
    if (onChange) onChange(val, event);
    setOpened(val.length >= (triggerLength || 0));

    const lowercasedName = val && val.toLocaleLowerCase();

    const found = lowercasedName && filteredItems.find((option) => option.label
      && (option.label.toLocaleLowerCase() === lowercasedName));

    if (found) onAutoComplete(found);
  }, [onChange, setOpened, triggerLength, filteredItems, onAutoComplete]);

  const handleItemClick = useCallback((item) => {
    if (onAutoComplete) onAutoComplete(item, { target: inputRef.current });
  }, [onAutoComplete, inputRef]);

  const handleInputFocus = useCallback((event) => {
    if (selectOnFocus) inputRef.current?.select();
    if (onSetShowAll) {
      onSetShowAll(false);
    } else {
      setShowAll(false);
    }
    if (onFocus) onFocus(event);
    setOpened(value.length >= (triggerLength || 0));
  }, [selectOnFocus, inputRef, onSetShowAll, onFocus, setOpened, value.length, triggerLength]);

  const handleInputBlur = useCallback((event) => {
    if (onBlur) onBlur(event);
  }, [onBlur]);

  const handleInputClick = useCallback(() => {
    setOpened(value.length >= (triggerLength || 0));
  }, [value, triggerLength, setOpened]);

  const handleCaretClick = useCallback(() => {
    (onSetShowAll || setShowAll)(true);
  }, [onSetShowAll]);

  useEffect(() => {
    if (scrollToSelected && opened && scrollRef.current) {
      const containerRect = scrollRef.current.getBoundingClientRect();

      const selectedNode = scrollRef.current.querySelector(".selected");

      if (selectedNode) {
        const selectedNodeRect = selectedNode.getBoundingClientRect();

        scrollRef.current.scrollTop = selectedNodeRect.top - containerRect.top;
      }
    }
  }, [scrollToSelected, opened]);

  return (
    <DropDown
      disabled={disabled}
      className={classNames(Css.autoCompleteInput, SIZES[size], disabled && Css.disabled, className)}
      opened={!readOnly && opened && !!(children || filteredItems.length)}
      setOpened={setOpened}
      onClick={onClick}>
      <div className={Css.toggle}>
        {prepend}
        <Input
          {...restProps}
          {...({ [(useTooltip && !opened) ? "data-tooltip" : "title"]: value })}
          size={size}
          inputRef={inputRef}
          name={name}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          value={value}
          valid={valid}
          invalid={invalid}
          className={classNames(Css.input, inputClassName)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onClick={handleInputClick} />
        {!disabled && !readOnly && useCaret && !!items.length && (
          <DropDownToggle onClick={handleCaretClick}>
            <DropDownCaret className={Css.caret} />
          </DropDownToggle>
        )}
      </div>
      <DropDownContent
        portal={portal}
        disabled={disabled}
        alignRight={alignListRight}
        className={classNames(Css.dropDown, dropDownClassName)}>
        {children}
        {!readOnly && opened && !!filteredItems.length && (
          <>
            {!!dropDownLabel && (
              <div className={Css.label}>{dropDownLabel}</div>
            )}
            <div className={Css.content}>
              <div className={Css.scroll} ref={scrollRef}>
                {groups.map((group) => {
                  const groupItems = group ? filteredItems.filter((option) => option?.group === group.value) : filteredItems;

                  return !!groupItems.length && (
                    <Fragment key={group ? group.value : 0}>
                      {!!group && !!group.label && (
                        <div className={Css.groupLabel} key={group.value}>{group.label}</div>
                      )}
                      {groupItems.map((item, index) => {
                        const label = item.label || item;

                        const key = `${label}${index}`;

                        return (
                          <DropDownMenuItem
                            key={key}
                            value={item}
                            title={renderLabel ? null : label}
                            className={classNames(Css.listItem, value === label && "selected")}
                            selected={value === label}
                            disabled={item.disabled}
                            onClick={handleItemClick}>
                            <div className={Css.listItemInner}>
                              {renderLabel ? renderLabel(item) : label}
                            </div>
                          </DropDownMenuItem>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </DropDownContent>
    </DropDown>
  );
};

export default React.memo(AutoCompleteInput);
